import {collection, doc, orderBy, query, where} from "firebase/firestore";
import {firestore} from "../firebase";
import {extendBaseStore} from "./base-store";
import apiClass from "../api/api";
const api = apiClass()

import {useBrandsStore} from "./brands-store";


export const usePostQueueRef = () =>{
    return collection(firestore, `brands/${useBrandsStore().currentBrandId}/post_queue`)
}


export let usePostQueueStore = {
    state: () => ({
    }),
    getters: {
        postQueue: (state) => {
            return state.recordsArray
        }
    },
    actions: {
        async generateReviewLink() {
            const res =  await api.post("/posts/generateReviewLink", {
                brand_id: useBrandsStore().currentBrandId
            })
            if (res.data.success) {
                return res.data
            }
        },
        async removePostFromQueue(postId) {
            await this.deleteRecord(postId)
        },
        async getPostQueue() {
            return await this.fetchRecords(
                [orderBy("created_at", "desc")]
            )
        },
        async getReviewQueue(reviewId) {
          const res=  await api.post("/reviews/getPostQueueFromReview", {
                review_id: reviewId
            })
            if (res.data.success) {
                console.log(res.data.post_queue);
                this.setRecords(res.data.post_queue)
                return res.data.post_queue
            }
        },
        async fetchPostFromQueue(postId) {
            return await this.fetchRecord(postId)
        },
        async addPostToPostQueue(postId, dateTime, timeZone, providers) {
            const res = await api.post("/posts/addPostToPostQueue", {
                post_id: postId,
                date_time: dateTime,
                time_zone: timeZone,
                providers: providers
            })
            if (res.data.success) {
               return await this.fetchPostFromQueue(postId)
            }
        }
    },
    persist: false,
}

usePostQueueStore = extendBaseStore("post-queue-store", usePostQueueRef, usePostQueueStore)
