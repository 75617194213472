import {collection, limit, orderBy, Timestamp, where} from "firebase/firestore";
import {onValue, ref} from "firebase/database";
import {db, firestore} from "../firebase";
import apiClass from "../api/api";
import {usePillarStore} from "./pillar-store";
import {extendBaseStore} from "./base-store";
import {useBrandsStore} from "./brands-store";
import {usePostQueueStore} from "./post-queue-store";

const api = apiClass()
const longApi = apiClass(true)

export const usePostsRef = () => collection(firestore, "posts")

export let usePostStore = {
    state: () => ({
        STATES: {
            APPROVED: "approved",
            REJECTED: "rejected",
            PENDING: "pending",
        },
        page_size: 9,
    }),
    getters: {
        posts: (state) => {
            return state.recordsArray
        },
        currentPost: (state) => {
            return state.currentRecord
        }
    },
    actions: {
        async watchPost(postId){
          return this.watchRecord(postId)
        },
        async updateReviewPostText(postId, postText, reviewId) {
            const res = await api.post("/reviews/updatePostText", {
                post_id: postId,
                post_text: postText,
                review_id: reviewId
            })
            if (res.data.success) {
                return await this.fetchPost(postId)
            }
        },
        async approveReviewPost(postId, reviewId) {
            const res = await api.post("/reviews/approvePost", {
                post_id: postId,
                review_id: reviewId
            })

        },
        async approvePost(postId) {
            const res = await api.post("/posts/approvePost", {
                post_id: postId
            })
            if (res.data.success) {
                return await this.fetchPost(postId)
            }
        },
        async rejectReviewPost(postId, reviewId) {
            const res = await api.post("/reviews/rejectPost", {
                post_id: postId,
                review_id: reviewId
            })
        },
        async generatePostByIdea(brandId) {
            const res = await api.post("/posts/generatePostIdea", {
                brand_id: brandId
            })
            if (res.data.success) {
                return res.data.post_idea
            }
        },
        async generateIdeasForPost(){
            const res = await api.post("/posts/generateIdeasForPost", {
                brand_id: useBrandsStore().currentBrandId
            })
            if (res.data.success) {
                return res.data.post_ideas
            }
        },
        async generatePostGraphics(postId) {
            const res = await longApi.post("/posts/generatePostGraphics", {
                post_id: postId
            })
            if (res.data.success) {
                return await this.fetchPost(postId)
            }
        },
        setCurrentPost(postId) {
            this.current_record_id = postId
        },
        async deletePost(postId) {
            try {
                await this.deleteScheduledPost(postId)
                await this.removePostFromQueue(postId)
            } catch (e) {
                console.error(e);
            }
            console.log("delete post");
            await this.deleteRecord(postId)
        },
        async removePostFromQueue(postId) {
            try {
                console.log("removePostFromQueue ", postId);
                usePostQueueStore().removePostFromQueue(postId)
            } catch (e) {
                console.error("removePostFromQueue",e);
            }
        },
        async addImageToPost(post_id, image_url) {
            const res = await api.post("/posts/addImageToPost", {
                post_id,
                image_url
            })
            if (res.data.success) {
                await this.fetchPost(post_id)
            }
        },
        async addImageFileToPost(post_id, file_type, file_id, file_storage_path, grid_id) {
            const res = await api.post("/posts/addImageFileToPost", {
                post_id,
                file_type,
                file_id,
                file_storage_path,
                grid_id
            })
            if (res.data.success) {
                await this.fetchPost(post_id)
            }
        },
        async schedulePost(postId, dateTime, tz, providers) {
            const res = await api.post("/posts/schedule", {
                post_id: postId,
                date_time: dateTime,
                time_zone: tz,
                providers
            })
            if (res.data.success) {
                return await this.fetchPost(postId)
            }
            return false
        },
        async deleteScheduledPost(postId) {
            const res = await api.post("/posts/deleteScheduledPost", {
                post_id: postId,
            })
            if (res.data.success) {
                return await this.fetchPost(postId)
            }
            return false
        },
        async createPost({pillarId, postIdea, postSettings, isDailyPost = false}) {
            let res
            if (pillarId) {
                res = await api.post("/posts/createPostFromPillar", {
                    pillar_id: pillarId,
                    is_daily_post: isDailyPost
                })
            }

            if (postIdea) {
                res = await api.post("/posts/createPostFromIdea", {
                    post_idea: postIdea,
                    post_settings: postSettings,
                    brand_id: useBrandsStore().currentBrand.id
                })
            }

            if (!res?.data?.success) {
                return
            }
            this.setRecord(res.data.post, true)

            return res.data.post
        },
        async watchPostText(post) {
            post.post_text = ""
            const postDBRef = ref(db, "posts/" + post.id + "/post_text");

            const unsubscribe = onValue(postDBRef, (snapshot) => {
                if (!snapshot.val()) {
                    return
                }
                if(snapshot.val() === "%END%"){
                    return unsubscribe();
                }
                post.post_text = snapshot.val()
                this.setRecord({...post})
            })

            return unsubscribe
        },
        async translatePost(post_id) {
            const res = await api.post("/posts/translate", {
                post_id,
            })
            if (res.data.success) {
                await this.fetchPost(post_id)
            }
        },
        async savePostTranslation(post, post_translation) {
            if (!post_translation) {
                return
            }
            post.translations.en = post.post_text

            const data = {
                translations: post.translations,
                post_text: post_translation
            }

            return this.updatePost(post.id, data)
        },
        async updatePost(postId, post) {
            if(post.created_at){
                if(post.created_at.seconds){
                    post.created_at = Timestamp.fromMillis(post.created_at.seconds * 1000)
                }else{
                    delete post.created_at
                }
            }else{
                delete post.created_at
            }
            return await this.updateRecord(postId, post)
        },
        async getPost(postId) {
            return this.getRecord(postId)
        },
        async fetchPost(postId) {
            return this.fetchRecord(postId)
        },
        async fetchPostsByPillar(pillarId) {
            await this.fetchRecords([
                where("pillar_id", "==", pillarId),
                orderBy("created_at", "desc")
            ])

            return this.posts
        },
        async fetchMorePostsByPillar(pillarId) {
            const queries = [
                where("pillar_id", "==", pillarId),
                orderBy("created_at", "desc"),
            ]
            queries.push(limit(this.page_size))

            return await this.fetchMoreRecords(queries)
        },
        async fetchPostsByBrand(brandId) {
            const queries = [
                where("brand_id", "==", brandId),
                orderBy("created_at", "desc"),
            ]

            queries.push(limit(this.page_size))

            await this.fetchRecords(queries)

            return this.posts
        },
        async fetchMorePostsByBrand(brandId) {
            try {
                console.log("fetchMorePostsByBrand ", brandId);
                if(!brandId){
                    return []
                }
                const queries = [
                    orderBy("created_at", "desc"),
                ]
                if(brandId !== "all"){
                    queries.push(where("brand_id", "==", brandId))
                }
                queries.push(limit(this.page_size))

                return await this.fetchMoreRecords(queries)
            }catch (e) {
                console.log(e);
                return []
            }

        },

        async createPostModifications(post_id, post_text, post_modifications) {
            return api.post("/posts/createPostModifications", {
                post_id,
                post_text,
                post_modifications
            })
        }
    },
    persist: false,
}

usePostStore = extendBaseStore("post-store", usePostsRef, usePostStore)
