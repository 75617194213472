import {
    collection,
    orderBy,
    where,
    increment,
} from "firebase/firestore";
import {firestore} from "../firebase";
import apiClass from "../api/api";
const api = apiClass()

import {extendBaseStore} from "./base-store";
export const usePillarsRef = ()=> collection(firestore, "pillars")

export let usePillarStore = {
    state: () => ({
    }),
    getters: {
        pillars: (state) => {
            return state.recordsArray
        },
        currentPillar: (state) => {
            return state.currentRecord
        }
    },
    actions:{
        async fetchPillarsForReview(reviewId){
            const res = await api.post("/reviews/fetchPillarsForReview", {
                review_id: reviewId
            })
            if(res.data.success){
                const pillars = res.data.pillars
                console.log(pillars);
                this.setPillars(pillars)
            }
            return null
        },
        async deletePillar(pillarId){
            return await this.deleteRecord(pillarId)
        },
        async generateContextFieldValues(pillarId, currentContextFields){
            const res = await api.post("/pillars/generateContextFieldValues", {
                pillar_id: pillarId,
                current_context_fields: currentContextFields
            })
            if(res.data.success){
                return res.data.context_field_values
            }
            return null
        },
        async incrementPillarContextIndex(pillarId){
            await this.updateRecord(pillarId, {
                context_table_index: increment(1)
            })
        },
        async createPillar(pillar){
            await api.post("/pillars/create", {
                pillar,
            })

            return this.fetchPillars(pillar.brand_id)
        },
        async getPillar(pillarId) {
            return this.getRecord(pillarId)
        },
        setCurrentPillarId(pillarId){
            this.current_record_id = pillarId
        },
        setPillars(pillars){
            this.setRecords(pillars)
        },
        async fetchPillars(brandId){
            if(!brandId){
                return []
            }
            this.isLoading = true

            const pillars = await this.fetchRecords([
                where("brand_id", "==", brandId),
                orderBy("created_at", "desc")
            ])

            this.isLoading = false
            return pillars
        },
        async updatePillar(pillarId, pillar){
            if(!pillarId){
                return
            }
            if(pillar.context_fields_values){
                pillar.context_fields_values = pillar.context_fields_values.filter((value) => {
                    for (const key in value) {
                        if (value[key] !== "" && value[key] !== null) {
                            return true
                        }
                    }
                    return false
                })
            }

            return await this.updateRecord(pillarId, pillar)
        },
    }
}

usePillarStore = extendBaseStore("pillar-store", usePillarsRef, usePillarStore)
