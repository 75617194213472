import {defineStore} from "pinia";
import {deleteDoc, doc, getDoc, getDocs, query, startAfter, updateDoc, onSnapshot} from "firebase/firestore";
import {onValue} from "firebase/database";

export function extendBaseStore (name,collectionRef = null , options = {}) {
    const baseStore = {
        state: () => ({
            recordsArray: [],
            recordsMap: {},
            current_record_id: null,
            isLoading: false,
            lastCursorRecord: null,
            watchedRecords: {}
        }),
        getters:  {
            currentRecord: (state) => {
                if (state.current_record_id && state.recordsMap[state.current_record_id]) {
                    return state.recordsMap[state.current_record_id]
                } else if (state.recordsArray.length > 0 && !state.current_record_id) {
                    state.current_record_id = state.recordsArray[0].id
                    return state.recordsArray[0]
                }
                // else if (state.recordsArray.length === 0) {
                //     state.fetchRecords()
                // }
                return null
            },
            records: (state) => {
                return state.recordsArray
            }
        },
        actions: {
            watchRecord(recordId) {
                this.log(`watchRecord ${recordId}`)
                if(!collectionRef){
                    throw new Error("collectionRef is null")
                }
                try{
                    const unsub = onSnapshot(doc(collectionRef(), recordId), (snapshot) => {
                        const record = {id: snapshot.id, ...snapshot.data()}
                        console.log(record);
                        this.setRecord(record)
                    })
                    this.watchedRecords[recordId] = unsub
                    return unsub
                }catch (e) {
                    console.error(e)
                }

            },
            setRecord(record, first = false) {
                this.log(`setRecord ${record.id}`)
                this.recordsMap[record.id] = record

                const recordIndex = this.recordsArray.findIndex(p => p.id === record.id)
                if(recordIndex === -1){
                    if(first){
                        this.recordsArray.unshift(record)
                    }else{
                        this.recordsArray.push(record)
                    }
                }else{
                    this.recordsArray[recordIndex] = record
                }
            },
            async getRecord(recordId) {
                this.log(`getRecord ${recordId}`)
                if(!recordId){
                    return null
                }
                if (!this.recordsMap[recordId]) {
                    await this.fetchRecord(recordId)
                }
                return this.recordsMap[recordId]
            },
            async fetchRecord(recordId) {
                this.log(`fetchRecord ${recordId}`)
                this.isLoading = true
                if(!collectionRef){
                    throw new Error("collectionRef is null")
                }
                try{
                    const snapshot = await getDoc(doc(collectionRef(), recordId))
                    let record = snapshot.data()
                    if(record){
                        record = {id: snapshot.id, ...record}
                        this.setRecord(record)
                    }
                    return record

                }catch (e) {
                    // console.error(e)
                }finally {
                    this.isLoading = false
                }


            },
            async fetchRecords(queries = []) {
                this.log(`fetchRecords ${queries.length}`)
                this.isLoading = true
                if(!collectionRef){
                    throw new Error("collectionRef is null")
                }
                this.recordsArray = []
                const snapshot = await getDocs(query(collectionRef(),
                    ...queries
                ))

                const records = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
                if(snapshot.docs?.length > 0){
                    this.lastCursorRecord = snapshot.docs[snapshot.docs.length - 1]
                }
                await this.setRecords(records)

                this.isLoading = false
                return records
            },
            async fetchMoreRecords(queries = []) {
                this.log(`fetchMoreRecords ${queries.length}`)

                if(!collectionRef){
                    throw new Error("collectionRef is null")
                }
                if(this.isLoading){
                    return
                }

                try{
                    this.isLoading = true

                    if(this.lastCursorRecord){
                        queries.push(startAfter(this.lastCursorRecord))
                    }

                    const snapshot = await getDocs(query(collectionRef(),
                        ...queries
                    ))
                    const newRecords = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
                    if(!this.lastCursorRecord && newRecords.length > 0){
                        this.setRecords(newRecords)
                    }else{
                        this.setRecords([...this.recordsArray, ...newRecords])
                    }
                    if(snapshot.docs?.length > 0){
                        this.lastCursorRecord = snapshot.docs[snapshot.docs.length - 1]
                    }
                    return newRecords
                }catch (e) {
                    console.error(e)
                    this.isLoading = false
                    return []
                }
                finally {
                    this.isLoading = false

                }

            },
            async fetchSpecificRecords(idsArray) {
                this.log(`fetchSpecificRecords ${idsArray.length}`)
                this.isLoading = true
                if(!collectionRef){
                    throw new Error("collectionRef is null")
                }
                this.recordsArray = []

                const promises = []
                for (const id of idsArray) {
                    promises.push(getDoc(doc(collectionRef(), id)))
                }

                const snapshots = await Promise.all(promises)
                const recordsArray = snapshots.map(snapshot => ({id: snapshot.id, ...snapshot.data()}))
                await this.setRecords(recordsArray)

                this.isLoading = false

                return recordsArray
            },
            async setCurrentRecord(recordId) {
                this.log(`setCurrentRecord ${recordId}`)
                if (!this.recordsMap[recordId]) {
                    await this.fetchRecord(recordId)
                }
                this.current_record_id = recordId
            },
            setRecords(records) {
                this.log(`setRecords ${records.length}`)

                this.recordsArray = records
                this.recordsMap = records.reduce((acc, record) => ({
                    ...acc,
                    [record.id]: record,
                }), {});
            },
            async deleteRecord(recordId) {
                this.log(`deleteRecord ${recordId}`)
                if(!recordId){
                    throw new Error("recordId is null")
                }
                if(!collectionRef){
                    throw new Error("collectionRef is null")
                }
                this.recordsArray = this.recordsArray.filter(p => p.id !== recordId)
                if(this.watchedRecords[recordId]){
                    this.watchedRecords[recordId]()
                    delete this.watchedRecords[recordId]
                }
                delete this.recordsMap[recordId]
                await deleteDoc(doc(collectionRef(), recordId))
            },
            async updateRecord(recordId, data) {
                this.log(`updateRecord ${recordId}`)
                if(!recordId){
                    throw new Error("recordId is null")
                }
                if(!collectionRef){
                    throw new Error("collectionRef is null")
                }
                await updateDoc(doc(collectionRef(), recordId), data)
                return await this.fetchRecord(recordId)
            },
            clearStore() {
                this.log(`cleanStore`)
                this.recordsArray = []
                this.recordsMap = {}
                this.current_record_id = null
                this.isLoading = false
                this.lastCursorRecord = null
            },
            log(message){
                // console.log(`${name} :: ${message}`)
            }
        },

    }
    const store = {}
    store.state = () => Object.assign(baseStore.state(), options?.state())
    store.actions = Object.assign(baseStore.actions, options?.actions)
    store.getters = Object.assign(baseStore.getters, options?.getters)
    store.persist = options?.persist

    return defineStore(name, store)
}


