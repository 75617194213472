import apiClass from "../api/api";
const api = apiClass()

import {collection, Timestamp} from "firebase/firestore";
import {db, firestore} from "../firebase";
import {useUserStore} from "./user-store";
import {extendBaseStore} from "./base-store";
import {onValue, ref} from "firebase/database";
import {onChildChanged} from "@firebase/database";


export const useBrandsRef = () =>{
    return collection(firestore, "brands")
}

/** @type {Store<Brand>} */
export const useBrandsStore = extendBaseStore("brands-store", useBrandsRef, {
    state: () => ({
    }),
    getters: {
        currentBrand: (state) => {
            return state.currentRecord
        },
        currentBrandId: (state) => {
            if(state.current_record_id){
                return state.current_record_id
            }else if(state.recordsArray.length > 0){
                state.current_record_id = state.recordsArray[0].id
                return state.current_record_id
            }
            return state.current_record_id
        },
        brands: (state) => {
            return state.recordsArray
        },
    },
    actions: {
        async dismissDailyPost(brandId) {
            this.currentRecord.daily_post_settings.last_posted_at = Timestamp.now()
            await api.post("/brands/dismissDailyPost", {
                brand_id: brandId
            })
        },
        async watchBrand(brandId) {
          return this.watchRecord(brandId)
        },
        async fetchBrandInfoFromWebsite(website) {
            const res = await api.post("/brands/fetchBrandInfoFromWebsite", {
                website
            })
            if(res.data.success){
                return res.data.brand_info
            }
            return null
        },
        async createBrandSocialConnections(brandId) {
            await api.post("/brands/createBrandSocialConnections", {
                brand_id: brandId
            })
        },
        async updateBrandDetails(brandDetails) {
            await api.post("/brands/updateBrandDetails", {
                brand_id: this.current_record_id,
                details: brandDetails
            })
        },
        async fetchBrandLogo(business_urls) {
            return await api.post("/brands/fetchLogo", {
                business_urls
            })
        },
        async deleteBrand(brandId) {
            const res = await api.post("/brands/deleteBrand", {
                brand_id: brandId
            })
            if(res.data.success){
                await this.deleteRecord(brandId)
            }
        },
        async createBrand(brand_fields){
            const res = await api.post("/brands/createBrand", {
                brand_fields
            })
            if(res.data.success){
                return {brand_id: res.data.brand_id, token: res.data.token}
            }
        },
        async updateBrandSettings(settings) {
            const res = await api.post("/brands/updateBrandSettings", {
                brand_id: this.current_record_id,
                settings: settings
            })
            if(res.data.success){
                await this.fetchBrand(this.current_record_id)
            }
        },
        async fetchBusinessUrlsInformation (business_urls, brand_id) {
            const res = await api.post("/brands/fetchBusinessUrlsInformation", {
                business_urls,
                brand_id
            })

            if(res.data.success){
                return res.data.business_info
            }
            return null
        },
        async generateInviteLink(email) {
            const res = await api.post("/brands/generateInviteLink", {
                email,
                brand_id: this.current_record_id
            })
            return res.data.link
        },
        async addUserToBrand(token) {
            return await api.post("/brands/addUserToBrand", {
                token
            })
        },
        async setCurrentBrand(brandId) {
            this.current_record_id = brandId
            const brand = await this.getBrand(brandId)
            this.refreshBrand(brandId)
        },
        setBrands(brands) {
            this.setRecords(brands)
        },
        async fetchBrand(brandId) {
            return await this.fetchRecord(brandId)
        },
        async getBrand(brandId) {
            return await this.getRecord(brandId)
        },
        async fetchBrandForReview(reviewId) {
            const res = await api.post("/reviews/fetchBrandForReview", {
                review_id: reviewId
            })
            if(res.data.success){
                this.setRecord(res.data.brand)
                this.current_record_id = res.data.brand.id
            }
        },
        async refreshBrand(brandId){
            if(!brandId){
                return
            }
            try{
               await api.post("/brands/refresh", {
                    brand_id: brandId
                })
                return await this.fetchBrand(brandId)
            }catch (e) {
                // console.log(e)
            }
        },
        async getBrands(refresh = false) {
            if(!refresh && this.recordsArray.length > 0){
                return this.recordsArray
            }

            try {
                if(await useUserStore().getUserRole() === "admin"){
                    return this.fetchRecords()
                }else{
                    const userBrands = await useUserStore().fetchUserBrands()
                    const promises = []
                    for (let i = 0; i < userBrands?.length; i++) {
                        const userBrandId = userBrands[i]
                        promises.push(this.fetchRecord(userBrandId))
                    }
                    const res = await Promise.all(promises)
                    return res.filter((brand) => brand)
                }
            } catch (e) {
                console.error("getBrands", e)
                return []
            }
        }
    },
    persist: ["current_record_id"],
})
