import {signOut} from "firebase/auth";
import { useFirebaseAuth, getCurrentUser } from "vuefire"

const appName = process.env.VUE_APP_PROJECT_NAME

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/account/login.vue"),
    meta: {
      title: "Login",
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        const user = await getCurrentUser()
        if (user && !user.isAnonymous) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the account page
          next();
        }
      },
    },
  },
  {
    path: "/index.html",
    component: () => import("../App.vue"),
  },
  {
    path: "/brand-onboarding",
    name: "brand-onboarding",
    component: () => import("../views/dashboard/brand/onboarding/brand-onboarding.vue"),
    meta: {
      title: appName,
      authRequired: true,
      allowedAnonymous: true,
    },
  },
  {
    path: "/:brandId/brand-setup",
    name: "brand-setup",
    component: () => import("../views/dashboard/brand/onboarding/brand-setup.vue"),
    meta: {
      title: appName,
      authRequired: true,
      allowedAnonymous: true,
    },
  },
  {
    path: "/create-posts",
    name: "create-posts-funnel",
    component: () => import("../views/dashboard/leads/funnels/create-posts-funnel.vue"),
    meta: {
      title: appName,
      authRequired: true,
      allowedAnonymous: true,
    },
  },
  {
    path: "/beta-invite",
    name: "Beta Invite",
    component: () => import("../views/auth/invite-set-password.vue"),
    meta: {
      title: appName,
    },
  },
  {
    path: "/review/:reviewId",
    name: "Review",
    component: () => import("../views/dashboard/brand/reviews/review.vue"),
    meta: {
      title: appName,
      authRequired: true,
      allowedAnonymous: true,
    },
  },
  {
    path: "/reviews",
    name: "Review",
    component: () => import("../views/dashboard/brand/reviews/reviews-page.vue"),
    meta: {
      title: appName,
      authRequired: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/account/login.vue"),
    meta: {
      title: "Register",
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        const user = await getCurrentUser()
        if (user && !user.isAnonymous) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the account page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        const user = await getCurrentUser()
        if (user && !user.isAnonymous) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the account page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: appName,
      authRequired: true,
    },
    component: () => import("../views/dashboard/home.vue"),
  },
  {
    path: "/welcome",
    name: "welcome",
    meta: {
      title: appName,
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/templates/templates.vue"),
  },
  {
    path: "/create-template",
    name: "create template",
    meta: {
      title: appName,
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/templates/template-create.vue"),
  },
  {
    path: "/api-keys",
    name: "api-keys",
    meta: {
      title: appName,
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/settings/api-keys.vue"),
  },
  {
    path: "/template/:gridId",
    name: "Template Renderer",
    meta: {
      title: appName,
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/templates/template-renderer.vue"),
  },
  {
    path: "/template/:gridId/edit",
    name: "Template Editor",
    meta: {
      title: appName,
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/templates/template-editor.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        await signOut(await useFirebaseAuth())
        // localStorage.clear();
        sessionStorage.clear();
        next({ name: "login"});
      },
    },
    component: () => import("../views/auth/account/login"),
  },
  {
    path: "/admin/brands-list",
    name: "brands-list",
    meta: {
      title: "Brands List",
      authRequired: true,
    },
    component: () => import("../views/dashboard/admin/brands-list.vue"),
  },
  {
    path: "/brand/content-plans",
    name: "content-plans",
    meta: {
      title: "Content Plan",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/pillars/pillar-list-page.vue"),
  },
  {
    path: "/admin/image-styles-list",
    name: "image-styles-list",
    meta: {
      title: "Image styles list",
      authRequired: true,
    },
    component: () => import("../views/dashboard/admin/image-styles-list"),
  },
  {
    path: "/admin/all-posts-list",
    name: "all-posts-list",
    meta: {
      title: "All posts list",
      authRequired: true,
    },
    component: () => import("../views/dashboard/admin/all-posts-list.vue"),
  },
  {
    path: "/admin/post-templates/post-template-list",
    name: "post-template-list",
    meta: {
      title: "Post Template List",
      authRequired: true,
    },
    component: () => import("../views/dashboard/admin/post-templates/post-template-list"),
  },
  {
    path: "/brand/create-post/:brandId",
    name: "create-post",
    meta: {
      title: "Create Post",
      authRequired: true,
    },
    component: () => import("../views/dashboard/admin/create-post"),
  },
  {
    path: "/pillars/:pillarId",
    name: "pillar",
    meta: {
      title: "Pillar",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/pillars/pillar"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      title: "Settings",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/settings/settings.vue"),
  },

  {
    path: "/posts/:postId/translate-post",
    name: "translate-post",
    meta: {
      title: "Translate Post",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/posts/translate-post.vue"),
  },
  {
    path: "/posts/:postId/edit",
    name: "post-editor",
    meta: {
      title: "Edit Post",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/posts/post-editor.vue"),
  },
  {
    path: "/posts/:postId/post-image",
    name: "post-image",
    meta: {
      title: "Post Image",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/posts/post-image.vue"),
  },
  {
    path: "/posts/:postId/grid-editor/:gridId",
    name: "grid-editor",
    meta: {
      title: "Grid Editor",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/posts/post-grid-editor.vue"),
  },
  {
    path: "/brand/create-grid",
    name: "create-grid",
    meta: {
      title: "Grids",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/grids/create-grid.vue"),
  },
  {
    path: "/calendar",
    name: "brand-calendar",
    meta: {
      title: "Calendar",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/metricool/calendar.vue"),
  },
  {
    path: "/posts",
    name: "post-list",
    meta: {
      title: "Post List",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/posts/post-list-page.vue"),
  },
  {
    path: "/analytics",
    name: "analytics",
    meta: {
      title: "Analytics",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/metricool/analytics-page.vue"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/dashboard/brand/gallery/gallery-page.vue"),
    meta: {
      title: "Gallery",
      authRequired: true,
    },
  },
  {
    path: "/social-connections",
    name: "social-connections",
    meta: {
      title: "Social Connections",
      authRequired: true,
    },
    component: () => import("../views/dashboard/brand/metricool/social-connections.vue"),
  },
];
