import {defineStore} from "pinia";

export const useSnackbarStore = defineStore("snackbar-store", {
    state: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "info"
        },
    }),
    actions:{
        showSnackbar(text, color = "info"){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.color = color
        }
    },
})
