import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import appConfig from "../../app.config";
import {getCurrentUser, useFirebaseAuth} from "vuefire";
import {usePostStore} from "../stores/post-store";
import {useBrandsStore} from "../stores/brands-store";
import {useSnackbarStore} from "../stores/snackbar-store";
import {useUserStore} from "../stores/user-store";
import {getRedirectResult} from "firebase/auth";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0, left: 0 };
  },
});

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const allowedAnonymous = routeTo.matched.some((route) => route.meta.allowedAnonymous);

  console.log("routeTo.name", routeTo.name);
  console.log("authRequired", authRequired);

  if (!authRequired) return next();

  let currentUser = await getCurrentUser()
  console.log(currentUser);

  if(allowedAnonymous && (!currentUser || currentUser.isAnonymous)){
    if(!currentUser){
      await useUserStore().signInAnonymously()
    }

    return next()
  }

  if(currentUser?.isAnonymous && !allowedAnonymous){
    return next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
  }

  console.log(currentUser);
  console.log(routeTo.query);
  if(routeTo.name === "create-posts-funnel" && currentUser && !currentUser?.isAnonymous && !routeTo.query.brandId){
    const brands = await useBrandsStore().getBrands(true)
    console.log(brands.length);
    if(brands.length > 0 ){
        return next({path: "/"});
    }else{
      return next()
    }
  }

  if(routeFrom.name !== "logout" && routeTo.name !== "logout" && routeTo.name !== "register" && routeTo.name !== "login" && routeTo.name !== "brand-onboarding" && routeTo.name !== "brand-setup"){
    let shouldRefresh = routeFrom.name === "brand-onboarding" ||
        routeFrom.name === "social-connections" ||
        routeFrom.name === "brand-calendar" ||
        routeFrom.name === "login" ||
        !routeFrom.name
    try{
      const brands = await useBrandsStore().getBrands(shouldRefresh)

      if(brands.length === 0 ){
        return next({ name: "create-posts-funnel" });
      }
    }catch (e) {
      console.log(e);
      return next({ name: "brand-onboarding", query: { redirectFrom: routeTo.fullPath } });
    }

  }
  if(currentUser?.uid && !currentUser?.isAnonymous){
    return next()
  }else{
    next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
  }

});

router.beforeEach(async (routeTo, routeFrom, next) => {
  if(routeTo.params.postId){
    usePostStore().setCurrentPost(routeTo.params.postId)
  }
  return next()


});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
  document.title = `${routeTo.meta.title} | ${appConfig.title}`;
  // If we reach this point, continue resolving the route.
  next();
});

router.onError(error =>{
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload()
  }
});

export default router;
