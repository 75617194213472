import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAuth, connectAuthEmulator} from "firebase/auth";

const firebaseConfig = {
  appId: process.env.VUE_APP_APPId,
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MEASUREMENTID,
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp)
const auth = getAuth(firebaseApp);

if(process.env.NODE_ENV === "development"){
  // connectAuthEmulator( getAuth(), "http://127.0.0.1:9099", { disableWarnings: true })
}

export {
  firestore,
  db,
  firebaseApp,
  storage,
  auth
};
initializeAnalytics(firebaseApp);
