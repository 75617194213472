import { createApp } from "vue";
import App from "./App.vue";
import AOS from "aos";
import "aos/dist/aos.css";

import vClickOutside from "click-outside-vue3";
import Maska from "maska";
import VueFeather from "vue-feather";



import "@/assets/scss/config/minimal/app.scss";
import "@vueform/slider/themes/default.css";
import "@/assets/scss/mermaid.min.css";

// FakeBackend authentication
// import { configureFakeBackend } from "./helpers/fake-backend";
// configureFakeBackend();

import { VueFire, VueFireAuth } from "vuefire";
import { createPinia } from "pinia"
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

import i18n from "./i18n";
import router from "./router";

import { firebaseApp } from "./firebase";

// Firebase authentication
// import { initFirebaseBackend } from "./authUtils";
// initFirebaseBackend();

// Vuetify
import "vuetify/styles"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import * as labsComponents from "vuetify/labs/components"
import { library } from "@fortawesome/fontawesome-svg-core"
import {  fa } from "vuetify/iconsets/fa"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"


import { aliases, mdi } from "vuetify/iconsets/mdi-svg"
const vuetify = createVuetify({
    components:{
        ...components,
        ...labsComponents
    },
    directives,
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
            fa
        },
    },
    theme: {
        defaultTheme: "automarketlyTheme",
        themes: {
            automarketlyTheme: {
                colors: {
                    primary: "#fe547c"
                },
            }
        },
    },
})



    const textDirectionDirective = {
        handleRTL () { /* placeholder */ },
        unmounted(el, binding) {
            el.removeEventListener("input", binding.dir.handleRTL);
        },
        mounted(el, binding) {
            binding.dir.handleRTL = ()=>{
                const rtlChars = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
                let dir =  rtlChars.test(el.textContent) ? "rtl" : "ltr";
                if(el.classList.contains("v-textarea")){
                    dir =  rtlChars.test(el.querySelector("textarea").value) ? "rtl" : "ltr";
                }
                if (dir) {
                    el.style.direction = dir;
                    el.style.textAlign = "initial";

                    //handle vuetify textarea
                    if(el.querySelector("textarea")){
                        el.querySelector("textarea").dir = dir;
                    }
                }
            }
            el.addEventListener("input", binding.dir.handleRTL)
            binding.dir.handleRTL()

            setTimeout(()=>{
                binding.dir.handleRTL()
            }, 1000)
        },

    }



AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

const vueApp = createApp(App)

vueApp.component("font-awesome-icon", FontAwesomeIcon) // Register component globally
library.add(fas) // Include needed solid icons
library.add(far) // Include needed regular icons

vueApp.config.globalProperties.$appName = "Posteam";

  vueApp.use(pinia)
  .use(VueFire, {
    firebaseApp,
    modules: [
      VueFireAuth(),
    ],
  })
  .use(router)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(i18n)
  .use(vuetify)
  .directive("text-direction", textDirectionDirective)
  .mount("#app");

