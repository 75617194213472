import axios from "axios";
import { getCurrentUser } from "vuefire"
import {onValue, push, ref} from "firebase/database";
import {db} from "../firebase";


export default function(longApi = false) {
    let baseURL = process.env.VUE_APP_API_URL;
    if(longApi){
        baseURL = process.env.VUE_APP_LONG_API_URL;
    }

    const axiosInstance = axios.create({
        baseURL: baseURL,
        timeout: 4 * 60 * 1000,
    });

    axiosInstance.interceptors.request.use( onRequest,  onRequestError);
    axiosInstance.interceptors.response.use( onResponse , onResponseError );

    axiosInstance.stream = async (path, params, callback)=>{
        const streamsRef = ref(db, "streams");
        const streamKey = push(streamsRef).key;
        const streamRef = ref(db, `streams/${streamKey}`);

        const unsubscribe = onValue(streamRef, (snapshot) => {
            if(!snapshot.val()){
                return
            }
            callback(snapshot.val())
        });

        const res =  await axiosInstance.post(path, {
            ...params,
            stream_id: streamKey
        }, {
            timeout: 4 * 60 * 1000, // 4 minutes
        })

        unsubscribe()

        return res

    }

    return axiosInstance
}



async function onRequest(config){
    const currentUser = await getCurrentUser();

    if (currentUser?.accessToken) {
        config.headers = {
            Authorization: `Bearer ${currentUser?.accessToken}`,
        };
    } else {
        // prompt user to account?
        window.location.href = "/login";
    }

    return config;
}

async function onRequestError(error){
    return Promise.reject(error);
}

async function onResponse(response){
    // If response is OK, just return it
    return response;

}

const getNewFirebaseToken = async () => {
    const user =  await getCurrentUser();
    if (user) {
        return user.getIdToken(true);
    }
    throw new Error("No user logged in");
};

async function onResponseError(error){
    const originalRequest = error.config;
    // If the error is 401 Unauthorized and haven't retried yet
    if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;  // Mark the request as retried

        try {
            // Refresh the token
            const newToken = await getNewFirebaseToken();
            originalRequest.headers["Authorization"] = "Bearer " + newToken;

            console.log("Retrying with config:", originalRequest);
            // Retry the request with the new token
            return axios(originalRequest);
        } catch (tokenError) {
            // Handle any error that occurred during token refresh
            console.error("Error refreshing token", tokenError);
            return Promise.reject(error);
        }
    }

    // If the error isn't 401 or we've already retried, reject the promise
    return Promise.reject(error);
}

