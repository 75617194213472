import {defineStore} from "pinia";
import {getCurrentUser, useCurrentUser, useDocument, useFirebaseAuth} from "vuefire";
import {collection, doc, getDocs} from "firebase/firestore";
import {signInAnonymously} from "firebase/auth";
import {firestore, auth} from "../firebase.js";
import apiClass from "../api/api";
const api = apiClass()


export const useUserStore = defineStore("user-store", {
    state: () => ({
        user: useCurrentUser(),
        hideOnboardingDialog: false,
    }),
    getters: {
        userDoc(state){
            if(state.user){
                return useDocument(doc(collection(firestore, "users"), state.user.uid))
            }else{
                return null
            }
        },
    },
    actions:{
        async signInAnonymously(){
            return await signInAnonymously(auth)
        },
        async fetchUserBrands(){
            const brand_ids = []
            const user = await getCurrentUser()
            console.log(user);
            try{
                if(!user){
                    return []
                }
                const  userBrandsSnapshot = await getDocs(collection(doc(collection(firestore, "users"), user.uid), "user_brands"))
                userBrandsSnapshot.forEach((doc) => {
                    brand_ids.push(doc.id)
                })

                return brand_ids
            }catch (e) {
                console.error(e);
                return []
            }

        },
        async fetchUserDoc(){
            this.user = await getCurrentUser()
            console.log(this.user);
            if(this.user){
                return await this.userDoc.value
            }
            return null
        },
        async isAdmin(){
            return await this.getUserRole() === "admin"
        },
        async getUserRole(){
            if(this.user){
                const userTokenData = await this.user?.getIdTokenResult()
                return userTokenData?.claims?.role
            }
            return null
        }
    },
    persist: {
        storage: localStorage,
        paths: ["hideOnboardingDialog"],
    },
})
